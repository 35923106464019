@import url("//cdn.web-fonts.ge/fonts/bpg-nino-mtavruli/css/bpg-nino-mtavruli.min.css");
@import url("//cdn.web-fonts.ge/fonts/dejavu-sans/css/dejavu-sans.min.css");

html {
  font-size: 62.5%;
}

body {
  font-family: "Poppins", sans-serif;
}

.container {
  height: 100svh;
  display: flex;
}

.dark {
  background: #03001F;
}

.main {
  flex: 12;
  height: 100svh;
  width: calc(100vw - 29.2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
  position: relative;
}

.chats {
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  width: 100%;
  padding-left: 12.5%;
  padding-right: 12.5%;
  height: 100svh;
  scrollbar-color: lightgray;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chats::-webkit-scrollbar {
  width: inherit;
}


.chat-message {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  width: fit-content;
}

.chat-message-content {
  border-radius: 0.5rem;
  padding: 2rem 3rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  width: fit-content;
}

.chat-message-footer {
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/*.like-dislike-icons {*/
/*  position: absolute;*/
/*  bottom: -2rem;*/
/*  right: 0.5rem;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  gap: 0.5rem;*/
/*}*/

/*.like-dislike-icons i {*/
/*  font-size: 1.6rem;*/
/*}*/

.whiteLikeDislikeIcon {
  color: white;
}

/*.like-dislike-icons i:hover {*/
/*  cursor: pointer;*/
/*}*/


.text {
  line-height: 1.8;
  white-space: pre-line;
  word-break: break-word;
  text-align: start;
  font-family: "DejaVu Sans", sans-serif;
  min-height: 100%;
}

.text a {
  color: #ff66b2;
  font-weight: normal;
}

.text a:hover {
  color: #fe017e;
}

.text-white {
  color: #fff;
}

.darkBackground {
  background: #1C1E3A;
}

.lightBackground{
  background: #f5f5f5;
}

.bot > p {
  font-weight: normal;
}

.chatFooter {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding-left: 12.5%;
  padding-right: 12.5%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.send-input-container {
  padding: 0.5rem;
  background: #f8f8f5;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  width: 100%;
}

.darkInput {
  background: rgba(28, 30, 58, 1);
}

.lightInput {
  color: white;
}

.send-input {
  width: calc(100% - 3rem);
  outline: none;
  padding: 1.25rem;
  height: min-content;
  font-family: "DejaVu Sans", sans-serif;
}

.send-input-gray-placeholder::placeholder {
  color: lightgray;
}

.send-input,
.send {
  background: transparent;
  border: none;
}

.send {
  margin-top: 0.6rem;
}

.send i {
  font-size: 1.8rem;
}

.sendColor {
  color: #784ef8;
}

.send:hover {
  cursor: pointer;
}

.chatFooter > p {
  margin: 0 auto 0;
  font-weight: normal;
  letter-spacing: 0.1rem;
  text-align: center;
  line-height: 1.2;
  font-family: "DejaVu Sans", sans-serif;
}

.white-footer-text {
  color: white;
}

.black-footer-text {
  color: black;
}

.content-buttons-container {
  width: 100%;
  padding: 0.3rem 0;
  overflow-x: auto;
  white-space: nowrap;
}

.content-buttons-container::-webkit-scrollbar {
  height: 0.2rem;
}

.content-buttons-container::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
}

.content-buttons-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.content-button {
  font-size: 1.4rem;
  border: 2px solid #fe017e;
  padding: 1rem 3rem;
  border-radius: 0.3rem;
  transition: 0.2s ease-in-out;
  text-wrap: wrap;
  font-family: "DejaVu Sans", sans-serif;
  margin-right: 1.8rem;
  white-space: nowrap;
}

.content-button:last-child {
  margin-right: 0;
}

.content-button:hover {
  color: #fff;
  background-color: #784ef8;
  border-color: #784ef8;
  cursor: pointer;
  transform: translateY(-0.25rem);
}

.content-button:active {
  transform: scale(0.9);
}

.light-button {
  color: black;
  background-color: #fafafa;
}

.dark-button {
  color: white;
  background-color: #282a4e;
}

/* chat loading */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader {
  border: 0.6rem solid #f3f3f3;
  border-top: 0.6rem solid #03a9f4;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  animation: spin 1s linear infinite;
}

.chat-message-content .avatar{
  width: 3.2rem;
  margin-right: 1.5rem;
  border-radius: 50%;
  align-self: flex-start;

}

.burgerMenu {
  font-size: 2.8rem;
  position: absolute;
}

.hidden {
  display: none;
}

.theme-toggle-container {
  position: absolute;
  top: 2%;
  right: 4%;
  font-size: medium;
}

.theme-toggle-container:hover {
  cursor: pointer;
}

.theme-toggle-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.theme-toggle-slot {
  position: relative;
  height: 2em;
  width: 4em;
  border-radius: 2em;
  background-color: white;
  transition: background-color 250ms;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.theme-toggle-checkbox:checked ~ .theme-toggle-slot {
  background-color: #374151;
}

.theme-toggle-button {
  height: 1.3em;
  width: 1.3em;
  border-radius: 50%;
  margin-right: 0.4em;
  background-color: #ffeccf;
  box-shadow: inset 0 0 0 0.15em #ffbb52;
  transition: background-color 250ms, border-color 250ms,
    transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}

.theme-toggle-checkbox:checked ~ .theme-toggle-slot .theme-toggle-button {
  background-color: #485367;
  box-shadow: inset 0 0 0 0.15em white;
  margin-left: 0.4em;
}


.sun-icon-wrapper {
  height: 1.2em;
  width: 1.2em;
  opacity: 1;
  margin-left: 0.4em;
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}

.theme-toggle-checkbox:checked ~ .theme-toggle-slot .sun-icon-wrapper {
  opacity: 0;
  display: none;
}

.theme-toggle-checkbox:checked ~ .theme-toggle-slot .moon-icon-wrapper {
  opacity: 1;
  display: block;
}

.theme-toggle-checkbox:not(:checked) ~ .theme-toggle-slot .moon-icon-wrapper {
  opacity: 0;
  display: none;
}


.moon-icon-wrapper {
  height: 1.2em;
  width: 1.2em;
  margin-right: 0.4em;
  opacity: 0;
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2.5, 0.46, 0.71);
}

div#wave {
  margin-left: 0.5rem;
  text-align: center;
}

.dot {
  display: inline-block;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  margin-right: 0.3rem;
  animation: wave 1.3s linear infinite;

  &:nth-child(2) {
    animation-delay: -1.1s;
  }

  &:nth-child(3) {
    animation-delay: -0.9s;
  }
}

.dotLightColor {
  background: #784ef8;
}

.dotGrayColor {
  background: #545454;
}

.ai-icon-container {
  margin-right: 1.5rem;
  margin-top: 0.3rem;
  color: white;
  background-color: #026592;
  min-width: 3.5rem;
  min-height: 3.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ai-icon-container p {
  font-size: 1.8rem;
}

.chat-message-footer .provider {
  text-transform: uppercase;
  background-image: linear-gradient(to right, #3498db, #5dade2, #85c1e9, #2ecc71);
  -webkit-background-clip: text; 
  background-clip: text; 
  color: transparent;
  background-size: 300%;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  background-position: -100%;
  animation: animatedText 5s infinite alternate-reverse;
  font-size: 1.2rem;
}

.blur {
  filter: blur(1rem);
}

@keyframes animatedText {
  to{
    background-position: 100%; 
  }
}


@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-1.5rem);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1024px) {
  .burgerMenu {
    display: block;
  }

  .chats {
    padding-left: 4%;
    padding-right: 4%;
  }

  .chatFooter {
    padding: 1rem 4%;
  }

  .burgerMenu {
    position: absolute;
    top: 2%;
    left: 4%;
  }


  .container {
    display: block;
  }

  .main {
    width: 100%;
  }
}
