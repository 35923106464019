@import url("//cdn.web-fonts.ge/fonts/dejavu-sans/css/dejavu-sans.min.css");

.sideBar {
  border-right: 1px solid rgb(100, 100, 100);
  background: #03001f;
  color: white;
  height: 100svh;
  max-width: 29.2rem;
  z-index: 9999;
  transition: transform 0.3s ease-in-out;
  position: relative;
}

.upperPartofSideBar {
  padding: 2rem;
  border-bottom: 1px solid rgb(100, 100, 100);
  height: 93%;
  overflow: hidden;
}

.upperPartTop {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 2.5rem;
  gap: 2rem;
  position: relative;
}


.chat-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chat-item {
  display: flex;
  align-items: center;
  background: transparent;
  color: rgba(222, 222, 222, 1);
  padding: 1.5rem;
  width: 100%;
  margin: 1rem auto;
  border-radius: 0.5rem;
  font-family: "DejaVu Sans", sans-serif;
  border: 1px solid rgba(98, 98, 98, 1);
}

.chat-item:hover {
  background-color: #03a9f4;
  color: white;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.newchatBtn {
  background: #fe017e;
  border: none;
  color: white;
  padding: 1.5rem;
  font-size: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 2rem;
  border-radius: 0.5rem;
  transition: 0.2s ease-in;
}

.newchatBtn span {
  padding-top: 0.4rem;
  letter-spacing: 1px;
  font-family: "BPG Nino Mtavruli", sans-serif;
}

.bar-icon {
  width: 100%;
  margin-top: 0.5rem;
}

.newchatBtn:hover {
  cursor: pointer;
  opacity: 0.8;
}

.newchatBtn:active {
  transform: scale(0.9);
}

.chats-container {
  overflow: auto;
  height: 82%;
  overflow-x: hidden;
}

.chats-container::-webkit-scrollbar {
  width: 0.3rem;
  background-color: transparent;
}

.chats-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 1rem;
  transition: background-color 0.3s;
}

.chats-container:hover::-webkit-scrollbar-thumb {
  background-color: #333;
}

.chats-container::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 1rem;
}

.centered-container {
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.history-icon {
  font-size: 4rem;
  color: #1c1a35;
}

.chats-container h3 {
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-weight: normal;
  letter-spacing: 1px;
  padding-left: 0.2rem;
  margin-top: 0.2rem;
}

.sidebar-footer-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding-left: 2rem;
  height: 7%;
}

.sidebar-footer-container:hover {
  cursor: pointer;
  background-color: rgb(20, 10, 60);
}

.currentUserPhoto {
  width: 10%;
  border-radius: 0.5rem;
}

.username {
  font-size: 1.6rem;
   font-family: "Inter", serif;
}

.close {
  font-size: 1.3rem;
  position: absolute;
  bottom: 80%;
  left: 98%;
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.report-icon-sidebar {
  font-size: 2rem;
  color: #03a9f4;
  margin-left: 3rem;
  display: none;
}

.report-icon-sidebar:hover {
  cursor: pointer;
}

.disableClick {
  pointer-events: none;
}

.sidebar-popup {
  position: absolute;
  bottom: 7.5%;
  left: 7%;
  background: #1c1a35;
  padding: 1rem;
  border-radius: 0.5rem;
  z-index: 1000;
  width: 86%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.1rem;
  font-family: "DejaVu Sans", sans-serif;
}

.upper {
  width: 100%;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #fff;
}

.action-icon {
  color: #fff;
  font-size: 1.6rem;
  margin-top: 0.5rem;
}

.popup-text {
  color: #fff;
  font-size: 1.6rem;
}

.popup-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.8rem;
  border-radius: 0.4rem;
  gap: 1rem;
}

.popup-container span {
  text-wrap: nowrap;
}

.popup-container:hover {
  background-color: #7f7f7f;
  cursor: pointer;
}

.popup-container .last {
  margin-top: 0.5rem;
}

.chat-skeleton-line {
  display: flex;
  align-items: center;
  background: #1c1a35;
  padding: 2rem;
  width: 100%;
  margin: 1rem auto;
  border-radius: 0.5rem;
  border: 1px solid #1c1a35;
}

.chat-skeleton-line{
  -webkit-animation: skeleton 1s ease-in-out forwards infinite;
  animation: skeleton 1s ease-in-out forwards infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}


@keyframes l26 {
  100% {transform:rotate(1turn)}
}

@-webkit-keyframes skeleton {
  0% {
    opacity: 0.3;
    transform: translateY(6px) scale(0.98);
  }
  85%, 100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

@keyframes skeleton {
  0% {
    opacity: 0.3;
    transform: translateY(6px) scale(0.98);
  }
  85%, 100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

@media screen and (max-width: 1024px) {
  .sideBar {
    position: fixed;
    left: 0;
    top: 0;
    transform: translateX(-101%);
  }

  .visible {
    transform: translateX(0);
  }

  .close {
    display: block;
  }

  .report-icon-sidebar {
    display: inline-block;
  }
}

@media screen and (max-height: 740px) {
  .upperPartofSideBar {
    height: 90%;
  }

  .chats-container {
    height: 78%;
  }

  .sidebar-popup {
    bottom: 11%;
  }

  .sidebar-footer-container {
    height: 10%;
  }
}

@media screen and (max-height: 630px) {
  .chats-container {
    height: 73%;
  }
}

@media screen and (max-height: 520px) {
  .chats-container {
    height: 70%;
  }
}

@media screen and (max-height: 450px) {
  .upperPartofSideBar {
    height: 85%;
  }

  .chats-container {
    height: 65%;
  }

  .sidebar-popup {
    bottom: 17%;
  }

  .sidebar-footer-container {
    height: 15%;
  }
}



